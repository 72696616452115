<template>
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.6389 14.8982H17.5906
      C16.1042 14.8973 14.8993 13.6933 14.8984 12.2069
      C14.8984 10.7205 16.1042 9.51654 17.5906 9.51562H21.6389"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18.049 12.1432H17.7373"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path fill-rule="evenodd" clip-rule="evenodd"
      d="M7.74766 3.5H16.3911
      C19.2892 3.5 21.6388 5.84951 21.6388 8.74766V15.9247
      C21.6388 18.8229 19.2892 21.1724 16.3911 21.1724H7.74766
      C4.84951 21.1724 2.5 18.8229 2.5 15.9247V8.74766
      C2.5 5.84951 4.84951 3.5 7.74766 3.5Z"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M7.03906 8.04162H12.438"
      :stroke="color" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'Wallet',
  props: {
    color: {
      type: String,
      default: '#ffffff',
    },
  },
};
</script>
